.includedServices {
	p {
		@apply
		yst-my-3;

		&.title {
			@apply
			yst-font-medium;
		}
	}

	ul.serviceList {
		@apply
		yst-ml-2
		yst-text-slate-600
		yst-list-disc
		/* stick with inline lists with nowrap as much as possible - though this looks horrible on mobile, so we adapt. */
		yst-pl-3.5
		sm:yst-pl-0
		sm:yst-ml-0
		sm:yst-list-inside
		sm:yst-whitespace-nowrap;

		li {
			@apply
			yst-border-t
			last:yst-border-b
			yst-py-3
			yst-px-0
			sm:yst-px-2;

			&::marker {
				@apply
				yst-text-xxs;
			}

			div {
				@apply
				yst-inline-flex
				yst-justify-between
				yst-gap-1
				/* Make full with, but compensate for the inline list bullets on sm+ screens */
				yst-w-full
				yst-pr-2.5
				sm:yst-pr-4;
			}

			a.link {
				@apply
				yst-font-medium
				yst-no-underline
				yst-text-primary-500;
			}
		}
	}
}
