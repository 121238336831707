
h1.title {
	@apply
	yst-mb-4;
}

.form {
	@apply
	yst-mt-3;

	p {
		@apply
		yst-italic;
	}

	.actions {
		@apply
		yst-flex
		yst-justify-end
		yst-gap-3
		yst-mt-4;
	}

	.error {
		@apply
		yst-mt-4;
	}
}

.successModal {

	@apply
	yst-text-center;

	.successPanel{
		@apply
		yst-max-w-96;
	}

	.check {
		@apply
		yst-mx-auto
		yst-size-6
		yst-fill-green-600
		yst-bg-green-100
		yst-rounded-full
		yst-box-content
		yst-p-4
		yst-mb-4;
	}

	.email{
		@apply
		yst-font-bold;
	}

	p {
		@apply
		yst-mb-4;
	}

	button {
		@apply
		yst-w-full
	}

}
