.subscription {
  .includedSection {
	  @apply
	  yst-text-slate-900
	  yst-font-normal;

	  .primaryTitle {
		  @apply
		  yst-w-full
		  yst-font-medium;
	  }

	  .secondaryText {
		  @apply
		  yst-text-slate-500
		  yst-w-full
		  yst-italic;
	  }

	  .pluginsContainer, .servicesContainer {
		  @apply
		  yst-flex
		  yst-flex-col
		  yst-gap-4;
	  }

	  .accessContainer {
		  & > * {
			  @apply
			  yst-ml-4;
		  }
	  }
  }

  svg {
    @apply yst-text-slate-400;

	  path {
		  stroke-width: 2px;
	  }

    width: 16px;
    height: 16px;
  }

  .status {
    cursor: default;
    opacity: 1;
    text-transform: capitalize;

    &.active {
      @apply
        yst-bg-green-100
        yst-text-green-800
        yst-border-green-200;
    }
  }

  .renewLink {
    svg {
      @extend svg;
      margin-right: 8px;
		@apply yst-text-slate-400;

		path {
			stroke-width: 2px;
		}
    }
  }

  .pill {
    border-radius: 6px;
  }

  .sitesRow {
    display: flex;
    align-items: center;

    td {
      flex: 1 0 auto;
    }

    td.sitesTableAction {
      flex: 0 1 auto;
    }
  }

  .cancelButton {
    @apply yst-text-red-600;

    &:hover {
	  @apply yst-text-red-700;
	}
  }
}
