.connectedSites {
	p.title {
		@apply
		yst-font-medium
		yst-my-3;
	}

	.addSiteButton {
		@apply
		yst-py-1;

		svg {
			@apply
			yst-inline
			yst-mr-1.5
			yst-fill-primary-500;
		}
	}

	ul {
		li {
			@apply
			yst-border-t
			yst-py-3
			yst-pr-2.5;

			div {
				@apply
				yst-inline-flex
				yst-justify-between
				yst-gap-1
				yst-w-full;
			}

			a.manage {
				@apply
				yst-font-medium
				yst-no-underline
				yst-text-primary-500;
			}
		}
	}
}
