.includedCourses {
	p.title {
		@apply
		yst-w-full
		yst-font-medium
		yst-py-2;
	}

	ul.courseList {
		@apply
		yst-text-slate-600
		yst-list-disc
		yst-list-inside;

		li {
			@apply
			yst-border-t
			yst-py-3
			yst-px-2;

			&::marker {
				@apply
				yst-text-xxs;
			}
		}
	}
}
