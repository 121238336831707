.connectedEmailAccess {
	p.title {
		@apply
		yst-font-medium
		yst-my-3;
	}

	.linkAccountButton {
		@apply
		yst-py-1;

		svg {
			@apply
			yst-inline
			yst-mr-1.5
			yst-fill-primary-500;
		}
	}

	.unlinkAccountButton {
		@apply
		yst-py-0;
	}

	ul.emailList {
		li {
			@apply
			yst-text-slate-600
			yst-border-t
			yst-py-3
			yst-pr-2.5;

			.emailAddress {
				@apply
				yst-grow;
			}

			&.pendingInvite {
				.emailAddress {
					@apply yst-text-slate-500;
				}
			}

			.status {
				@apply
				yst-italic;
			}

			div {
				@apply
				yst-inline-flex
				yst-justify-between
				yst-gap-4
				yst-w-full;
			}
		}
	}
}
