.details {
	@apply
	yst-border
	yst-border-slate-300
	yst-w-full
	yst-mb-4
	yst-rounded-md;

	&[open] {
		.showWhenOpen {
			display: initial;
		}

		.showWhenClosed {
			@apply yst-hidden;
		}
	}

	.showWhenOpen {
		@apply yst-hidden;
	}

	.summary {
		@apply
		yst-flex
		yst-justify-between
		yst-p-4;

		svg {
			@apply
			yst-min-w-6;
		}

		&::marker,
		&::-webkit-details-marker {
			@apply yst-hidden;
			content: "";
		}
	}

	.content {
		@apply
		yst-border-t
		yst-py-4
		yst-mx-4;
	}
}
