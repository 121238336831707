a.externalLink {


	svg.externalLinkIcon {
		@apply
		yst-inline-block
		yst-w-3
		yst-h-3
		yst-ml-1
	}

	&:visited > svg.externalLinkIcon {
		@apply
		yst-fill-primary-500
		hover:yst-fill-primary-400
		focus:yst-fill-primary-400
		visited:yst-fill-primary-500
		visited:hover:yst-fill-primary-400;
	}
}
